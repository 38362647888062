import React from "react";
import '../LinkStyle/links.css';


const PrivacyPolicy = () => {
    return(
        <>
            <div className="LinkBody">
                <div className="HeaderLink">
                    <div className="hrLink"></div>
                    <h1 className="privacy-policy">Privacy Policy</h1>
                    <div className="hrLink"></div>
                </div>
                <div className="privacypolicyDescription"></div>
            </div>
        </>
    );
}

export default PrivacyPolicy;