import Navbar from './components/Navbar';
import Home from './components/home/Home';
import AboutUS from './components/about/AboutUs';
import Footer from './components/footer/Footer';
import Service from './components/service/Service';
import Contact from './components/contact/contact';
import EnquiryPopup from './components/Nav/EnquiryPopup';
import Congratulations from './components/Nav/Congratulations';
import GSTRegistration from './components/service/productService/GSTRegistraton';
import Scrollanimation from './components/mobileAnimation/scroll-animation';
import Disclaimer from './components/Link/Disclaimer/disclaimer';
import TermsAndConditions from './components/Link/TermsAndConditions/termsandcondition';
import PrivacyPolicy from './components/Link/Privacypolicy/privacypolicy';
import ServiceEnquiry from './components/service/enquiryService/serviceEnquiry';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './Responsivedesign/styleres.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Scrollanimation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path='/service' element={<Service />} />
          <Route path='/contactus' element={<Contact />} />
          <Route path='/enquiryPopup' element={<EnquiryPopup />}/>
          <Route path='/congratulations' element={<Congratulations />}/>
          <Route path='/service/gstregistration' element={<GSTRegistration />}/>
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/serviceEnquiry' element={<ServiceEnquiry />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
