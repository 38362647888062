import React, { useEffect, useRef } from 'react';
import './resservice.css';
import fillingres from '../../../image/analyte.png';
import ifiling from '../../../image/register.png';
import register from '../../../image/Tax-apply.png';


const Resservice = () => {
    const boxesRef = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            const triggerBottom = window.innerHeight / 5 * 4;
            boxesRef.current.forEach(box => {
                const boxTop = box.getBoundingClientRect().top;
                if (boxTop < triggerBottom) {
                    box.classList.add('show');
                } else {
                    box.classList.remove('show');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="res-service-container">
            <h1 className="service-header">service</h1>
            <div ref={el => (boxesRef.current[0] = el)} className="res-container-one box">
                <img src={fillingres} alt="" />
                <div className="content-res">
                    <h3>GST Filling</h3>
                    <div className="resfilling-content">Learn everything about GST filing, from understanding its principles to mastering the process, ensuring compliance with tax regulations effortlessly.</div>
                </div>
            </div>
            <div ref={el => (boxesRef.current[1] = el)} className="res-container-two box">
                <img src={ifiling} alt="" />
                <div className="content-res">
                    <h3>Trademark Registration</h3>
                    <div className="resfilling-content">Trademark registration is the process of legally protecting your brand's identity, logo, or slogan from unauthorized use by others. By registering your trademark, you gain exclusive rights to use it in commerce, helping to safeguard your brand's reputation and market presence.</div>
                </div>
            </div>
            <div ref={el => (boxesRef.current[2] = el)} className="res-container-three box">
                <img src={register} alt="" />
                <div className="content-res">
                    <h3>Tax Filling</h3>
                    <div className="resfilling-content">Tax filing is the process of submitting tax returns to the government, ensuring compliance with tax laws and regulations.</div>
                </div>
            </div>
        </div>
    );
};

export default Resservice;
