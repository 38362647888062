import React from "react";
import '../Navbar.css';
import '../../Responsivedesign/styleres.css';

const Header = () => {
    return(
        <>
            <h1 className="logoStyle">BuSiNeSs FiLiNgS HuB</h1>
        </>
    );
}

export default Header; 