import React, { useState, useEffect } from 'react';
import Header from "./Nav/Header";
import Nav from "./Nav/Nav";
import Contact from "./Nav/Contact";
import './Navbar.css';
import '../Responsivedesign/styleres.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={`navigationWeb ${isSticky ? 'sticky' : ''}`}>
                <Header isSticky={isSticky} />
                <Nav className="navRes" isSticky={isSticky} />
                <button onClick={toggleNav} className='resnav-container'>
                    <ion-icon name="apps"></ion-icon>
                </button>
                <ul className={`res-navigationUL ${isNavOpen ? 'open' : ''}`}>
                    <Link className='res-Link' to="/">
                        <li>Home</li>
                    </Link>
                    <Link className='res-Link' to="/about">
                        <li>About Us</li>
                    </Link>
                    <Link className='res-Link' to="/service">
                        <li>Services</li>
                    </Link>
                    <Link className='res-Link' to="/blog">
                        <li>Blog</li>
                    </Link>
                    <Link className='res-Link' to="/contactus">
                        <li>Contact Us</li>
                    </Link>
                    <Link to="/enquiryPopup" className='res-enquiry'>
                        <li>Enquriy Now!</li>
                    </Link>
                </ul>
            </div>
            <Contact className="contactNavi" />
        </>
    );
};

export default Navbar;
