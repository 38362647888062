import React, { useState } from "react";
import './serviceEnquiry.css';

function ServiceEnquiry() {
    const [serviceenquiryData, setServiceenquiryData] = useState({
        serviceenquiryName: '',
        serviceenquiryNumber: '',
        serviceenquiryEmail: '',
        serviceenquiryService: ''
    });

    const serviceenquiryChange = (e) => {
        const { name, value } = e.target;
        setServiceenquiryData({ ...serviceenquiryData, [name]: value });
    };

    const serviceenquirySubmit = (e) => {
        e.preventDefault();

        const serviceenquiryDataEncoded = new URLSearchParams();
        for (const key in serviceenquiryData) {
            serviceenquiryDataEncoded.append(key, serviceenquiryData[key]);
        }

        fetch('http://127.0.0.1:5000/serviceDataset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: serviceenquiryDataEncoded
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Service Enquiry Data sent successfully:', data);
                setServiceenquiryData({
                    serviceenquiryName: '',
                    serviceenquiryNumber: '',
                    serviceenquiryEmail: '',
                    serviceenquiryService: ''
                });
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };
    return (
        <>
            <div className="service-enquiry">
                <h1>Any Enquiry!</h1>
                <form onSubmit={serviceenquirySubmit}>
                    <input type="text" id="serviceEnquiry" name="serviceenquiryName" value={serviceenquiryData.serviceenquiryName} onChange={serviceenquiryChange} placeholder="Name" required />
                    <input type="text" id="serviceEnquiryNumber" placeholder="Number" name="serviceenquiryNumber" value={serviceenquiryData.serviceenquiryNumber} onChange={serviceenquiryChange} required />
                    <input type="email" id="serviceEnquiryEmail" placeholder="Email" name="serviceenquiryEmail" value={serviceenquiryData.serviceenquiryEmail} onChange={serviceenquiryChange} required />
                    <input type="text" id="serviceRequired" placeholder="GST Registration" readOnly />
                    <select name="serviceenquiryService" value={serviceenquiryData.serviceenquiryService} onChange={serviceenquiryChange} required>
                        <option value="">Select Plan</option>
                        <option value="Basic">Basic</option>
                        <option value="Essential">Essential</option>
                        <option value="Advance">Advance</option>
                    </select>
                    <button type="submit">GET NOW!</button>
                </form>
            </div>
        </>
    );

}

export default ServiceEnquiry;