import React, { useEffect } from 'react';
import giftimage from '../../image/giftimage.png';
import './abouthome.css';

const AboutHome = () => {
  useEffect(() => {
    const handleScroll = () => {
      const reveals = document.querySelectorAll('.aboutHome-Background-container');
      const windowHeight = window.innerHeight;
      const revealPoint = 150;

      reveals.forEach(reveal => {
        const revealTop = reveal.getBoundingClientRect().top;
        if (revealTop < windowHeight - revealPoint) {
          reveal.classList.add('active');
        } else {
          reveal.classList.remove('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className="aboutHome-Background-container">
        <div className="about-home-content"></div>
        <p className="aboutHomeText">About US!</p>
        <div className="aboutHomeContent"></div>
        <div className="sideBar-abouthome">
          <div className="sideBarOne">
            <div className="project-count">
              +22
            </div>
            <h4>Our Project</h4>
          </div>
          <div className="sideBarTwo">
            <div className="project-count">
              +105
            </div>
            <h4>Our Client</h4>
          </div>
        </div>
      </div>
      <div className="res-comment-about">
        BuSiNeSs FiLiNgS HuB is a trusted and highly-rated company dedicated to providing comprehensive tax and business registration services. Their offerings include GST filing, tax filing, GST registration, company registration, income tax filing, virtual accountant services, and trademark registration. Efilingshub's expert team ensures accuracy and efficiency in every service, tailored to meet individual and business needs. Clients consistently praise their professionalism and customer-centric approach, reflected in excellent Google ratings. With BuSiNeSs FiLiNgS HuB, you can rest assured that your tax and registration needs are handled with the utmost care and expertise, making them the ideal partner for all your financial and legal compliance requirements.
      </div>
      <div className='res-comment-contact'>
        <h2>Contact US!</h2>
        <div className="location-details-res comment-details">
          <ion-icon className="res-icon" name="location-sharp"></ion-icon>
          <div className="content address">
            9/3, Chandrakalyan,1st Floor, Nanneri Kazhagam St No.2, Coimbatore - 641 001
          </div>
        </div>
        <br />
        <div className="phone-details-res comment-details">
          <ion-icon className="res-icon" name="call-sharp"></ion-icon>
          <div className="content phoneNumber">+91 422 4547479</div>
        </div>
        <br />
        <div className="mail-details-res comment-details">
          <ion-icon className="res-icon" name="mail-unread-sharp"></ion-icon>
          <div className="content gmail">barathandassociates@gmail.com</div>
        </div>
      </div>
      <div className="giftImage">
        <img src={giftimage} alt="" />
      </div>
    </>
  );
}

export default AboutHome;