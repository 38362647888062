import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './contact.css'

const Contact = () => {
  const [contactData, setContactData] = useState({
    contactName: '', contactNumber: '', contactMessage: ''
  });

  const contactChange = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  const contactSubmit = (e) => {
    e.preventDefault();

    const contactDataEncoded = new URLSearchParams();
    for (const key in contactData) {
      contactDataEncoded.append(key, contactData[key]);
    }

    fetch('http://127.0.0.1:5000/contactDataset', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: contactDataEncoded
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Contact Data sent successfully:', data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="Contact-container">
      <div className="contact-container-blog blogcontent">
        <div className="contact-container-fill-form">
          <h1>Contact Us</h1>
          <div className="contact-content-text">
            Feel free to contact us any time. we will get
            back you as soon as we can.
          </div>


          <div className="form-details-container">
            <form onSubmit={contactSubmit}>
              <input type="text" id="contact-name" placeholder="Name" name="contactName" value={contactData.contactName} onChange={contactChange} required />
              <input type="text" id="contact-email" placeholder="Number" name="contactNumber" value={contactData.contactNumber} onChange={contactChange} required />
              <textarea id="conatct-message" placeholder="Message" name="contactMessage" value={contactData.contactMessage} onChange={contactChange} ></textarea>
              <button className="form-button-blogs" type="submit">SEND</button>
            </form>
          </div>
        </div>

        <div className="contact-container-details">
          <h1 className="header-contact-details-container">Info</h1>
          <div className="container-contactInfo">
            <div className="mailId-container info-management">
              <ion-icon name="mail-outline"></ion-icon>
              <h4>barathandassociates@gmail.com</h4>
            </div>
            <div className="contact-number-container info-management">
              <ion-icon name="call-outline"></ion-icon>
              <h4>+91 422 4547479</h4>
            </div>
            <div className="address-container info-management">
              <ion-icon name="business-outline"></ion-icon>
              <h4>9/3, Chandrakalyan,1st Floor, Nanneri Kazhagam St No.2, Coimbatore - 641 001</h4>
            </div>
            <div className="timing-container info-management">
              <ion-icon name="time-outline"></ion-icon>
              <h4>09:00 - 18:00</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;