import React from "react";
import './scroll-animation.css';

function Scrollanimation(){
    return(
        <>
            <div className="scroll-container"></div>
        </>
    );
}

export default Scrollanimation;