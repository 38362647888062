import React from "react";
import '../LinkStyle/links.css';


const Disclaimer = () => {
    return(
        <>
            <div className="LinkBody">
                <div className="HeaderLink">
                    <div className="hrLink"></div>
                    <h1 className="disclaimer">Disclaimer</h1>
                    <div className="hrLink"></div>
                </div>
                <div className="disclaimerDescription"></div>
            </div>
        </>
    );
}

export default Disclaimer;