import React, { useState } from "react";
import { Link } from "react-router-dom";
import './gstregistration.css';

function GSTRegistration() {
    const [activeContent, setActiveContent] = useState(1);
    const [clickedButton, setClickedButton] = useState(null);
    const [serviceenquiryData, setServiceenquiryData] = useState({
        serviceenquiryName: '',
        serviceenquiryNumber: '',
        serviceenquiryEmail: '',
        serviceenquiryService: ''
    });

    const showContent = (index) => {
        setActiveContent(index);
        setClickedButton(index);
    };

    const serviceenquiryChange = (e) => {
        const { name, value } = e.target;
        setServiceenquiryData({ ...serviceenquiryData, [name]: value });
    };

    const serviceenquirySubmit = (e) => {
        e.preventDefault();

        const serviceenquiryDataEncoded = new URLSearchParams();
        for (const key in serviceenquiryData) {
            serviceenquiryDataEncoded.append(key, serviceenquiryData[key]);
        }

        fetch('http://127.0.0.1:5000/serviceDataset', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: serviceenquiryDataEncoded
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Service Enquiry Data sent successfully:', data);
                setServiceenquiryData({
                    serviceenquiryName: '',
                    serviceenquiryNumber: '',
                    serviceenquiryEmail: '',
                    serviceenquiryService: ''
                });
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <div className="GSTRegistration-service">
            <div className="service-template">
                <div className="service-Overview">
                    <h2>Online GST Registration</h2>
                    <div className="revice-rating">
                        <h4>4.8</h4>
                        <h5>5836 Customers Reviews</h5>
                    </div>
                    <div className="service-button-container">
                        <button className={`button ${clickedButton === 1 ? 'active' : ''}`} onClick={() => showContent(1)}>Basic</button>
                        <button className={`button ${clickedButton === 2 ? 'active' : ''}`} onClick={() => showContent(2)}>Essential</button>
                        <button className={`button ${clickedButton === 3 ? 'active' : ''}`} onClick={() => showContent(3)}>Advance</button>
                    </div>
                    <div className="price-details-service">
                        <div className="content" id="content1" style={{ display: activeContent === 1 ? 'flex' : 'none' }}>
                            <div className="marketPrice-content">
                                <h3>Market Price :</h3>
                                <h3>₹ 4000</h3>
                            </div>
                            <div className="ourprice-content">
                                <h3>Our Price :</h3>
                                <div className="pricing-details-basic">
                                    <h2>₹ 2457 Excl GST</h2>
                                    <h3>₹ 2899 Incl GST</h3>
                                </div>
                            </div>
                            <div className="save-content">
                                <h3>You Save :</h3>
                                <h3>₹ 1543 (38%) </h3>
                            </div>
                        </div>
                        <div className="content" id="content2" style={{ display: activeContent === 2 ? 'flex' : 'none' }}>
                            <div className="marketPrice-content">
                                <h3>Market Price :</h3>
                                <h3>₹ 6000</h3>
                            </div>
                            <div className="ourprice-content">
                                <h3>Our Price :</h3>
                                <div className="pricing-details-basic">
                                    <h2>₹ 3999 Excl GST</h2>
                                    <h3>₹ 4719 Incl GST</h3>
                                </div>
                            </div>
                            <div className="save-content">
                                <h3>You Save :</h3>
                                <h3>₹ 2001 (33%) </h3>
                            </div>
                            <h5>GST Registration + 3 Month GST Return Filing </h5>
                        </div>
                        <div className="content" id="content3" style={{ display: activeContent === 3 ? 'flex' : 'none' }}>
                            <div className="marketPrice-content">
                                <h3>Market Price :</h3>
                                <h3>₹ 12000</h3>
                            </div>
                            <div className="ourprice-content">
                                <h3>Our Price :</h3>
                                <div className="pricing-details-basic">
                                    <h2>₹ 8340 Excl GST</h2>
                                    <h3>₹ 9799 Incl GST</h3>
                                </div>
                            </div>
                            <div className="save-content">
                                <h3>You Save :</h3>
                                <h3>₹ 3696 (31%) </h3>
                            </div>
                            <h5>GST Registration + 1 Year GST Return Filing </h5>
                        </div>
                    </div>
                </div>
                <div className="res-button">Enquiry Now!</div>
                {/* <div className="service-line"></div> */}
                <div className="benefit-content">
                    <div className="tagOne">
                        <h1>Benefit's</h1>
                    </div>
                    <div className="benefit-content">
                        <div className="text-gstContent"><b>Bank Loans: </b>GST registration and GST return filing show company activity and track record. GST return data inform the bank and NBFC lending to companies. Thus, GST registration can formalize and credit your company.</div><br />
                        <div className="text-gstContent"><b>Supplier Onboarding: </b>Reputable firms often require GST registration Online for supplier onboarding. GST registration can increase the company.</div><br />
                        <div className="text-gstContent"><b>ECommerce: </b>GST registration is required to sell online on Amazon, Flipkart, Snapdeal, Zomato, Swiggy, etc.</div><br />
                        <div className="text-gstContent"><b>Input Tax Credit: </b>GST-registered businesses can receive GST from customers and offset it against GST paid on purchases. Thus, GST registration reduces fees and boosts margins.</div>
                    </div>
                    <div className="button-gstService">
                        <Link className="button-btn" to="/serviceEnquiry">Get Service</Link>
                    </div>
                </div>
            </div>

            {/* <div className="service-enquiry">
                    <h1>Any Enquiry!</h1>
                    <form onSubmit={serviceenquirySubmit}>
                        <input type="text" id="serviceEnquiry" name="serviceenquiryName" value={serviceenquiryData.serviceenquiryName} onChange={serviceenquiryChange} placeholder="Name" required />
                        <input type="text" id="serviceEnquiryNumber" placeholder="Number" name="serviceenquiryNumber" value={serviceenquiryData.serviceenquiryNumber} onChange={serviceenquiryChange} required />
                        <input type="email" id="serviceEnquiryEmail" placeholder="Email" name="serviceenquiryEmail" value={serviceenquiryData.serviceenquiryEmail} onChange={serviceenquiryChange} required />
                        <input type="text" id="serviceRequired" placeholder="GST Registration" readOnly />
                        <select name="serviceenquiryService" value={serviceenquiryData.serviceenquiryService} onChange={serviceenquiryChange} required>
                            <option value="">Select Plan</option>
                            <option value="Basic">Basic</option>
                            <option value="Essential">Essential</option>
                            <option value="Advance">Advance</option>
                        </select>
                        <button type="submit">GET NOW!</button>
                    </form>
                </div> */}
        </div>
    );
}

export default GSTRegistration;
